<template>
    <div class="form-main">
        <el-form :model="topicForm" :rules="rulesTopic" ref="topicForm" label-width="120px" class="topic-form">
            <el-form-item label="题库分类" prop="category_id">
                <theory-libaray :libaray-arr="categoryArr" @changeCategory="changeCategory"></theory-libaray>
            </el-form-item>
            <el-form-item label="题目" prop="title">
                <tinymce-editor @editorContent="editorTxt" :value="topicForm.title" txt="*（1）标题不用写序号，如：1,2,3"></tinymce-editor>
            </el-form-item>
            <el-form-item label="题目解析" prop="analysis">
                <tinymce-editor @editorContent="editorAnalysis" :value="topicForm.analysis"></tinymce-editor>
            </el-form-item>
        </el-form>
        <div class="form-submit-btns">
            <el-button size="mini" @click="cancelToManage">取消</el-button>
            <el-button type="primary" class="btn-blue" size="mini" @click="submitForm('topicForm')">保存</el-button>
        </div>
    </div>
</template>

<script>
import tinyMceEditor from "@/components/plugs/tinyMceEditor";
import TheoryLibaray from "@/components/plugs/theoryLibaray";
import {theoryTopicAdd} from "@/utils/apis";
import common from "@/utils/common";

export default {
    name: "askTopic",
    components: {
        TheoryLibaray,
        'tinymce-editor': tinyMceEditor,
    },
    props: {
        formData: Object
    },
    mounted() {
        if (this.id) {
            let result = common.formatAsk(this.formData);
            this.categoryArr = result.category_arr;
            this.topicForm = {...this.topicForm,...result.data};
        }
    },
    data() {
        return {
            id: this.$route.query.id ? Number(this.$route.query.id) : 0,
            topicForm: {
                type: 5,
                category_id: '',
                title: '',
                options: '[]',
                answer: '[]',
                analysis: '',
            },
            categoryArr: [],
            rulesTopic: {
                category_id: [
                    {required: true, message: '请选择题库分类', trigger: 'blur'},
                ],
                title: [
                    {required: true, message: '请填写题目', trigger: 'blur'}
                ],
                analysis: [
                    {required: true, message: '请填写问答题分析', trigger: 'blur'}
                ]
            },

        }
    },
    methods: {
        changeCategory(val) {
            this.topicForm.category_id = val[val.length - 1];
        },
        editorAnswer(val) {
            this.topicForm.answer = val.trim();
        },
        editorAnalysis(val) {
            this.topicForm.analysis = val.trim();
        },
        editorTxt(val) {
            this.topicForm.title = val.trim();
        },
        cancelToManage() {
            this.$router.push('/admin/theorymanage');
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formdata = Object.assign({}, this.topicForm);
                    if (this.id) {
                        formdata = {...formdata, theory_topic_id: this.id}
                    }
                    theoryTopicAdd(formdata).then(res => {
                        if (res.code === 200) {
                            if (this.id) {
                                this.$confirm('试题编辑成功，是否继续编辑试题？', '后续操作', {
                                    confirmButtonClass: 'deep-blue-confirm',
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: "success"
                                }).then(() => {

                                }).catch(() => {
                                    this.$router.push('/admin/theorymanage');
                                });
                            } else {
                                this.$confirm('试题添加成功，是否继续添加试题么？', '后续操作', {
                                    confirmButtonClass: 'deep-blue-confirm',
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: "success"
                                }).then(() => {
                                    this.$refs[formName].resetFields();
                                }).catch(() => {
                                    this.$router.push('/admin/theorymanage');
                                });
                            }
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }).catch(err => {
                        console.log('err', err);
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.form-submit-btns {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>