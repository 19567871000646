<template>
    <div class="editor-body">
        <editor :init="init" v-model="content"></editor>
        <div class="warning-txt">{{txt}}</div>
    </div>
</template>

<script>
import tinymce from "tinymce"
import Editor from "@tinymce/tinymce-vue"
export default {
    name: "tinyMceEditor",
    data() {
        return {
            init: {
                height: 300,
                language_url: "./tinymce/langs/zh_CN.js",
                language: 'zh_CN',
                plugins: 'wordcount,image,lists, advlist, charmap, link',
                menubar: false,
                toolbar: 'bold italic underline forecolor backcolor insertBlank | bullist numlist link|charmap  formatselect fontsizeselect | image wordcount '
                         + 'undo redo',
                setup(editor) {
                    var blankplaceholder = () => {
                        return '______';
                    };
                    editor.ui.registry.addButton('insertBlank', {
                        text: '插入填空项',
                        tooltip: '插入填空项',
                        onAction: function (_) {
                            editor.insertContent(blankplaceholder());
                        }

                    })

                }
            },
            content: this.value,
        }
    },
    props: {
        value: String,
        txt: {
            type: String,
            default: ''
        }
    },
    components: {
        'editor': Editor
    },
    watch: {
        content(val) {
            this.$emit('editorContent',val);
        },
        value(newVal) {
            this.content = newVal;
        }
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
.editor-body {
    padding-right: 20px;
    .warning-txt {
        color: #FF0000;
        font-size: 12px;
    }
}

</style>