<template>
    <div class="theory-libaray-main">
        <el-cascader v-model="categoryArr"
                     :options="topicTheoryLib"
                     :props="props"
                     @change="libarayType"
        >
        </el-cascader>
    </div>
</template>

<script>
import {theoryLibSelect} from "@/utils/apis";

export default {
    name: "theoryLibaray",
    props: {
        libarayArr: Array
    },
    data() {
        return {
            categoryArr: [],
            topicTheoryLib: [],
            props: { label: 'name', value: 'id'},
        }
    },
    mounted() {
        this.initList();
        this.categoryArr = this.libarayArr;
    },
    watch: {
        libarayArr(val) {
            this.categoryArr = val;
        }
    },
    methods: {
        initList() {
            theoryLibSelect().then(res => {
                if (res.code === 200) {
                    this.topicTheoryLib = res.data;
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                console.log('err', err);
            });
        },
        libarayType(val) {
            this.$emit('changeCategory', val);
        }
    }
}
</script>

<style scoped>

</style>