<template>
    <div class="option-body">
        <div class="option-list">
            <el-checkbox-group v-model="checkArr" @change="confirmOption">
                <div class="option-item" v-for="(item, index) in getOptions" :key="index">
                    <el-checkbox :label="index">
                        <span class="radio-item">{{index | formData}}</span>
                        <el-input class="input-deep" v-model="item.name"></el-input>
                    </el-checkbox>
                    <div class="remove-icon">
                        <i class="el-icon-close" @click="removeOption(index)"></i>
                    </div>
                </div>
            </el-checkbox-group>
        </div>
        <div class="option-add-btn">
            <el-button type="primary" size="mini" class="btn-blue" @click="addOption"><i class="el-icon-plus"></i>&nbsp;&nbsp;添加选项</el-button>
        </div>


    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import common from "@/utils/common";
export default {
    name: "multipleChoiceOption",
    data() {
        return {
            checkArr: [],
        }
    },
    created() {
        if (this.$route.query.id) {
            this.checkArr = this.answerInfo;
        }
    },
    beforeDestroy() {
        this.setOptionsValue([]);
        this.setAnswerValue([])
    },
    computed: {
        ...mapGetters(['getOptions','answerInfo']),
    },
    filters: {
        formData(val) {
            return common.numberToAlphabet(val);
        }
    },
    watch: {
        answerInfo(val) {
            if (val) {
                this.checkArr = val;
            }

        }
    },
    methods: {
        ...mapActions(['setOptionsValue', 'setAnswerValue']),
        confirmOption(value) {
            this.setAnswerValue(value);
        },
        addOption() {
            if (this.getOptions.length >= 6) {
                this.$message.warning('多选题的答案最多不能超过6个');
                return;
            }
            this.getOptions.push({name: ''});
            this.setOptionsValue(this.getOptions);
        },
        removeOption(index) {
            this.getOptions.splice(index, 1);
        }
    }
}
</script>

<style scoped lang="scss">
.input-deep {
    margin: 0 12px 0 17px;
}
.option-list {
    .option-item {
        position: relative;
        width: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin: 10px 0;
        .remove-icon {
            position: absolute;
            top: 0;
            right: -19px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                font-size: 14px;
                color: #666;
                cursor: pointer;
            }
        }
        &::v-deep .el-checkbox__input {
            &.is-checked {
                &+.el-checkbox__label {
                    color: #1122D8;
                }
                .el-checkbox__inner {
                    background-color: #1122D8;
                    border-color: #1122D8;
                }

            }
        }
    }
}

</style>