<template>
    <div class="option-body">
        <div class="option-list">
            <div class="option-item" v-for="(item, index) in getOptions" :key="index">
                <span>{{index + 1}}</span>
                <el-input class="input-deep" v-model="item.name"></el-input>
                <i class="el-icon-close" @click="removeOption(index)"></i>
            </div>
        </div>
        <div class="option-btn-add">
            <el-button type="primary" size="mini" class="btn-blue" @click="addOption"><i class="el-icon-plus"></i>&nbsp;&nbsp;添加选项</el-button>
        </div>
    </div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: "fillBlankOption",
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['getOptions'])
    },
    watch: {

    },
    methods: {
        addOption() {
            this.getOptions.push({name: ''});
        },
        removeOption(index) {
            this.getOptions.splice(index, 1);
        }
    }
}
</script>

<style scoped lang="scss">
.option-body {
    .option-list {
        .option-item {
            width: 300px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
            margin: 10px 0;
            .input-deep {
                margin: 0 6px 0 15px;
            }
            i {
                cursor: pointer;
            }
        }
    }
}

</style>