<template>
    <div class="option-body">
        <div class="option-list">
            <el-radio-group v-model="answerIndex" @change="answerEd">
                <div class="option-item" v-for="(item, index) in getOptions" :key="index">
                    <el-radio :label="index">
                        <span class="radio-item">{{index | formData}}</span>
                        <el-input class="input-deep" v-model="item.name"></el-input>
                        <i class="el-icon-close" @click="removeOption(index)"></i>
                    </el-radio>
                </div>
            </el-radio-group>
        </div>
        <div class="option-add-btn">
            <el-button type="primary" size="mini" class="btn-blue" @click="addOption"><i class="el-icon-plus"></i>&nbsp;&nbsp;添加选项</el-button>
        </div>
    </div>
</template>

<script>
import {mapGetters,mapActions} from "vuex"
export default {
    name: "singleChoiceOption",
    computed: {
        ...mapGetters(['getOptions', 'answerInfo'])
    },
    filters: {
        formData(val) {
            let letter = '';
            switch (val) {
                case 0:
                    letter = 'A';
                    break;
                case 1:
                    letter = 'B';
                    break;
                case 2:
                    letter = 'C';
                    break;
                case 3:
                    letter = 'D';
                    break;
            }
            return letter;
        }
    },
    data() {
        return {
            answerIndex: this.answerInfo ? this.answerInfo : '',
        }
    },
    watch: {
        answerInfo(val) {
            this.answerIndex = val;
        }
    },
    created() {
        if (this.$route.query.id) {
            this.answerIndex = this.answerInfo;
        }
    },
    beforeDestroy() {
        this.setOptionsValue([])
        this.setAnswerValue('')
    },
    methods: {
        ...mapActions(['setOptionsValue','setAnswerValue']),
        removeOption(index) {
            this.getOptions.splice(index, 1);
            this.setOptionsValue(this.getOptions)
        },
        addOption() {
            if (this.getOptions.length > 3) {
                this.$message.warning('选择题的选项最多不能超过4个！');
                return;
            }
            this.getOptions.push({name: ''});
            this.setOptionsValue(this.getOptions)
        },
        answerEd(val) {
            this.setAnswerValue(val);
        }
    }
}
</script>

<style scoped lang="scss">
.input-deep {
    margin: 0 12px 10px 17px;
}
.option-list {
    &::v-deep .el-radio__input {
        &.is-checked {
            .el-radio__inner {
                background-color: #1122D8;
                border-color: #1122D8;

            }
            &+.el-radio__label {
                color: #1122D8;
            }
        }
    }
}

</style>