<template>
    <div class="form-main">
        <el-form :model="topicForm" :rules="rulesTopic" ref="topicForm" label-width="120px" class="topic-form">
            <el-form-item label="题库分类" prop="category_id">
                <theory-libaray :libaray-arr="categoryArr" @changeCategory="changeCategory"></theory-libaray>
            </el-form-item>
            <el-form-item label="题目" prop="title">
                <tinymce-editor @editorContent="editorTxt" :value="topicForm.title" :txt="txt"></tinymce-editor>
            </el-form-item>
            <el-form-item label="答案选项" prop="options">
                <judgment-option></judgment-option>
            </el-form-item>
        </el-form>
        <div class="form-submit-btns">
            <el-button size="mini" @click="cancelToManage">取消</el-button>
            <el-button type="primary" class="btn-blue" size="mini" @click="submitForm('topicForm')">保存</el-button>
        </div>
    </div>

</template>

<script>
import tinyMceEditor from "@/components/plugs/tinyMceEditor";
import TheoryLibaray from "@/components/plugs/theoryLibaray";
import {mapGetters,mapActions} from "vuex";
import {theoryTopicAdd} from "@/utils/apis";
import common from "@/utils/common";
import JudgmentOption from "@/components/topicComponents/judgmentOption";

export default {
    name: "judgmentTopic",
    components: {
        JudgmentOption,
        TheoryLibaray,
        'tinymce-editor': tinyMceEditor,
    },
    computed: {
        ...mapGetters(['getOptions', 'answerInfo'])
    },
    watch: {
        getOptions(val) {
            this.topicForm.options = 1;
        }
    },
    props: {
        formData: Object
    },
    mounted() {
        if (this.id) {
            let result = common.formatJson(this.formData);
            if (result.options.length > 2) {
                this.setOptionsValue(result.options.splice(0, 2));
            }
            if (result.options.length === 0 || result.options.length < 2) {
                this.setOptionsValue([{name: '对'}, {name: '错'}])
            }
            this.setAnswerValue(result.data.answer[0]);
            this.categoryArr = result.category_arr;
            this.topicForm = {...this.topicForm,...result.data};
        }

    },
    data() {
        var that = this;
        var validatorOptions = (rule, value, callback) => {
            for (let i = 0; i < that.getOptions.length; i++) {
                if (that.getOptions[i].name.length === 0) {
                    return callback(new Error('请输入选项的值'));
                }
            }
            if (that.answerInfo.length === 0) {
                return callback(new Error('请设置判断题正确答案'));
            }
            callback();
        };
        return {
            id: this.$route.query.id ? Number(this.$route.query.id) : 0,
            topicForm: {
                type: 3,
                category_id: '',
                title: '',
                options: '',
                answer: ''
            },
            categoryArr: [],
            rulesTopic: {
                category_id: [
                    {required: true, message: '请选择题库分类', trigger: 'blur'},
                ],
                title: [
                    {required: true, message: '请填写题目', trigger: 'blur'}
                ],
                options: [
                    {required: true, message: '请设置答案选项', trigger: 'blur'},
                    {validator: validatorOptions, trigger: 'blur'}
                ],
                answer: [
                    {required: true, message: '请设置题目答案', trigger: 'blur'}
                ]
            },
            txt: '*（1）标题不用写序号，如：1,2,3',
        }
    },
    methods: {
        ...mapActions(['setOptionsValue', 'setAnswerValue']),
        changeCategory(val) {
            this.topicForm.category_id = val[val.length - 1];
        },
        editorTxt(val) {
            this.topicForm.title = val.trim();
        },
        cancelToManage() {
            this.$router.push('/admin/theorymanage');
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let option = [];
                    this.getOptions.map((item) => {
                        option.push(item.name);
                    });
                    this.topicForm.options = JSON.stringify(option);
                    this.topicForm.answer = JSON.stringify([this.answerInfo]);
                    if (this.id) {
                        this.topicForm.theory_topic_id = this.id;
                    }
                    theoryTopicAdd(this.topicForm).then(res => {
                        if (res.code === 200) {
                            if (this.id) {
                                this.$confirm('试题编辑成功，是否继续编辑试题？', '后续操作', {
                                    confirmButtonClass: 'deep-blue-confirm',
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: "success"
                                }).then(() => {

                                }).catch(() => {
                                    this.$router.push('/admin/theorymanage');
                                });
                            } else {
                                this.$confirm('试题添加成功，是否继续添加试题么？', '后续操作', {
                                    confirmButtonClass: 'deep-blue-confirm',
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: "success"
                                }).then(() => {
                                    this.setOptionsValue([]);
                                    this.setAnswerValue('');
                                    this.$refs[formName].resetFields();
                                }).catch(() => {
                                    this.$router.push('/admin/theorymanage');
                                });
                            }

                        } else {
                            this.$message.warning(res.msg);
                        }
                    }).catch(err => {
                        console.log('err', err);
                    })

                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.form-submit-btns {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>