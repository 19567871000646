import { render, staticRenderFns } from "./multipleChoiceOption.vue?vue&type=template&id=2471aed6&scoped=true"
import script from "./multipleChoiceOption.vue?vue&type=script&lang=js"
export * from "./multipleChoiceOption.vue?vue&type=script&lang=js"
import style0 from "./multipleChoiceOption.vue?vue&type=style&index=0&id=2471aed6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2471aed6",
  null
  
)

export default component.exports