<template>
    <div class="option-body">
        <div class="option-list">
            <el-radio-group v-model="answerIndex" @change="answerEd">
                <div class="option-item" v-for="(item, index) in getOptions" :key="index">
                    <el-radio :label="index">
                        <el-input class="input-deep" v-model="item.name"></el-input>
                    </el-radio>
                </div>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
export default {
    name: "judgmentOption",
    data() {
        return {
            answerIndex: ''
        }
    },
    computed: {
        ...mapGetters(['getOptions','answerInfo'])
    },
    mounted() {
        if (this.$route.query.id) {
            this.answerIndex = this.answerInfo
        }
        this.setOptionsValue([{name: '对'}, {name: '错'}])
    },
    beforeDestroy() {
        this.setOptionsValue([]);
        this.setAnswerValue('');
    },
    watch: {
        answerInfo(val) {
            this.answerIndex = val;
        }
    },
    methods: {
        ...mapActions(['setOptionsValue', 'setAnswerValue']),
        answerEd(val) {
            this.setAnswerValue(val);
        }
    }
}
</script>

<style scoped lang="scss">
.option-list {
    .option-item {
        margin: 10px 0px;
        padding: 0 5px;
    }
    &::v-deep .el-radio__input {
        &.is-checked {
            .el-radio__inner {
                background-color: #1122D8;
                border-color: #1122D8;

            }
            &+.el-radio__label {
                color: #1122D8;
            }
        }
    }
}

</style>