<template>
    <div class="main-content">
        <div class="breadcrumb-line">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{path: '/admin/theorymanage'}">理论题</el-breadcrumb-item>
                <el-breadcrumb-item v-if="id">编辑试题</el-breadcrumb-item>
                <el-breadcrumb-item v-else>添加试题</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="topic-content">
            <el-scrollbar style="height: 100%">
                <div class="topic-main">
                    <div class="topic-form-item">
                        <div class="form-label">
                            <em>*</em> <span class="label-name">题型</span>
                        </div>
                        <div class="form-option">
                            <el-radio-group v-model="type" size="mini" @change="changeType">
                                <el-radio-button v-for="(item, key) in topicTypeList" :key="key" :label="item.value">{{item.label}}</el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                    <single-topic v-if="type === 1" :form-data="formaData"></single-topic>
                    <multiple-topic v-if="type === 2" :form-data="formaData"></multiple-topic>
                    <judgment-topic v-if="type === 3" :form-data="formaData"></judgment-topic>
                    <fillblank-topic v-if="type === 4" :form-data="formaData"></fillblank-topic>
                    <ask-topic v-if="type === 5" :form-data="formaData"></ask-topic>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import SingleTopic from "@/components/topicComponents/singleTopic";
import {theoryTopicDetail} from "@/utils/apis";
import MultipleTopic from "@/components/topicComponents/multipleTopic";
import JudgmentTopic from "@/components/topicComponents/judgmentTopic";
import FillblankTopic from "@/components/topicComponents/fillblankTopic";
import AskTopic from "@/components/topicComponents/askTopic";
export default {
    name: "theoryTopicDetail",
    components: {AskTopic, FillblankTopic, JudgmentTopic, MultipleTopic, SingleTopic},
    data() {
        return {
            id: this.$route.query.id ? this.$route.query.id : 0,
            type: this.$route.query.id ? null : 1,
            topicTypeList: [
                { label: '单选题', value: 1},
                { label: '多选题', value: 2},
                { label: '判断题', value: 3},
                { label: '填空题', value: 4},
                { label: '问答题', value: 5},
            ],
            formaData: {}
        }
    },
    mounted() {
        if (this.id) {
            this.topicInfo();
        }
    },
    methods: {
        changeType(val) {

        },
        topicInfo() {
            let param = {theory_topic_id: Number(this.id)};
            theoryTopicDetail(param).then(res => {
                if (res.code === 200) {
                    let result = res.data;
                    this.type = Number(result.type);
                    this.formaData = result;
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(err => {
                console.log('err', err);
            });
        }
    }
}
</script>

<style scoped lang="scss">
.breadcrumb-line {
    padding: 10px 0;
}
.topic-content {
    width: 100%;
    height: calc(100% - 34px);
    &::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.topic-main {
    padding: 20px 0;
    width: 100%;
    .topic-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        .form-label {
            width: 120px;
            text-align: right;
            padding-right: 12px;
            em {
                color: #F56C6C;
                margin-right: 2px;
            }
        }
    }
    &::v-deep .el-radio-button__orig-radio {
        &:checked+.el-radio-button__inner {
            background-color: #1122D8;
            border-color: #1122D8;
        }
    }
    .form-submit-btns {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &::v-deep .el-form-item {
        .el-switch {
            &.is-checked {
                .el-switch__core {
                    background-color: #1122D8;
                    border-color: #1122D8;
                }
            }
        }
    }
}

</style>